<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <b-card v-if="itemData && ($can('create', abilityRequired) || $can('update', abilityRequired))">
      <b-card-header>
        <h5>
          Patrol
        </h5>
      </b-card-header>
      <b-card-body>
        <!-- Input Fields -->
        <validation-observer
          ref="observer"
          #default="{ handleSubmit }"
        >
          <b-form
            @submit.prevent="handleSubmit(finalizePatrol)"
          >
            <!-- Field: Client Selection -->
            <b-row>
              <b-col
                v-if="typeof itemData.status === 'undefined' || itemData.status === 'New'"
                md="6"
              >
                <b-form-group
                  label="Client"
                  label-for="clientname"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="clientname"
                    rules="required"
                  >
                    <v-select
                      v-model="itemData.clientname"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="clientOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="clientname"
                      :disabled="theClientSite !== null"
                      :loading="!clientOptions.length"
                      :placeholder="!clientOptions.length ? 'Loading...' : 'Select Client'"
                      @input="handleSelectClient"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-else
                md="6"
              >
                <b-form-group
                  label="Client"
                  label-for="clientname"
                >
                  <h5>{{ itemData.clientname }}</h5>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Site selection -->
            <b-row>
              <b-col
                v-if="typeof itemData.status === 'undefined' || itemData.status === 'New'"
                md="6"
              >
                <b-form-group
                  label="Site"
                  label-for="sitename"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="sitename"
                    rules="required"
                  >
                    <v-select
                      v-model="itemData.sitename"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="siteOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="sitename"
                      :disabled="theClientSite !== null"
                      :loading="!siteOptions.length"
                      :placeholder="!siteOptions.length ? 'Loading...' : 'Select Site'"
                      @input="handleSelectSite"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-else
                md="6"
              >
                <b-form-group
                  label="Site"
                  label-for="sitename"
                >
                  <h5>{{ itemData.sitename }}</h5>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Patrol Name -->
            <b-row
              class="mb-2"
            >
              <b-col
                v-if="typeof itemData.status === 'undefined' || itemData.status === 'New'"
                md="6"
              >
                <b-form-group
                  label="Patrol Name"
                  label-for="patrolname"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="patrolname"
                    rules="required"
                  >
                    <v-select
                      v-model="itemData.patrolname"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="patrolnameOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="patrolname"
                      :disabled="theClientSite !== null"
                      :loading="!patrolnameOptions.length"
                      :placeholder="!patrolnameOptions.length ? 'Loading...' : 'Select Patrol Template'"
                      @input="handleSelectPatrolTemplate"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-else
                md="6"
              >
                <b-form-group
                  label="Patrol Name"
                  label-for="patrolname"
                >
                  <h5>{{ itemData.patrolname }}</h5>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Start Patrol Button -->
            <div
              v-if="thePatrolTemplate && typeof itemData.status !== 'undefined' && itemData.status === 'New'"
            >
              <b-row class="mt-3">
                <b-col
                  v-if="$can('create', abilityRequired)"
                  md="3"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click.prevent="handleStartPatrol"
                  >
                    Start Patrol
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <!-- ***** Incomplete Checkpoints List ***** -->
            <b-row
              v-if="theCheckpoints"
              class="mt-2 mb-1 pt-1 border-top-secondary"
            >
              <b-col
                md="12"
              >
                <h4>Incomplete Checkpoints</h4>
              </b-col>
            </b-row>
            <!-- Pause Patrol Button -->
            <div
              v-if="thePatrolTemplate && typeof itemData.status !== 'undefined' && itemData.status === 'Started'"
            >
              <b-row class="mt-1 mb-1">
                <b-col
                  v-if="$can('create', abilityRequired)"
                  md="3"
                >
                  <b-button
                    variant="warning"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click.prevent="handlePausePatrol"
                  >
                    Pause Patrol
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <!-- Resume Patrol Button -->
            <div
              v-if="thePatrolTemplate && typeof itemData.status !== 'undefined' && itemData.status === 'Paused'"
            >
              <b-row class="mt-1 mb-1">
                <b-col
                  v-if="$can('create', abilityRequired)"
                  md="3"
                >
                  <b-button
                    variant="warning"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click.prevent="handleResumePatrol"
                  >
                    Resume Patrol
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <!-- patrol_in_order messaging -->
            <b-row
              v-if="theCheckpoints"
            >
              <b-col
                v-if="thePatrolTemplate.patrol_in_order"
                md="12"
              >
                You MUST complete the below Checkpoints in order.
              </b-col>
              <b-col
                v-else
                md="12"
              >
                The below Checkpoints do NOT need to be completed in order.
              </b-col>
            </b-row>
            <!-- Checkpoint Modal -->
            <b-modal
              id="checkpoint-modal"
              centered
              hide-footer
              :title="checkpointDetailsModal.name + ' Checkpoint'"
            >
              <b-row>
                <b-col md="12">
                  {{ checkpointDetailsModal.point_description }}
                </b-col>
              </b-row>
              <!-- Checkpoint Images -->
              <b-row class="mt-1 mb-1">
                <b-col>
                  <template v-if="checkpointDetailsModal.docs_attachment_list">
                    <div
                      v-for="attachment in checkpointDetailsModal.docs_attachment_list"
                      v-show="showImages"
                      :key="attachment.access_url"
                      class="item-img text-center"
                    >
                      <thumbs-lazy-image-component
                        class="card-img-top"
                        :src="attachment.access_url"
                        src-placeholder="@/assets/images/noImage.png"
                      />
                    </div>
                  </template>
                </b-col>
              </b-row>
              <b-form @submit.prevent="handleCheckpointSubmit">
                <input
                  v-model="checkpointDetailsModal"
                  type="hidden"
                  name="checkpointDetails"
                >
                <div
                  v-if="itemData.status !== 'Paused' && !nfcScanned"
                  class="text-center"
                >
                  <b-img
                    src="@/assets/images/icons/nfc-scan-animation.gif"
                    width="75"
                    height="75"
                    alt="Scan NFC Tag"
                  />
                  <p class="mt-1">
                    Please scan the NFC tag
                  </p>
                </div>
                <!-- Flagged -->
                <b-form-group>
                  <b-form-checkbox
                    v-model="checkpointDetailsModal.flagged"
                    name="flagged"
                  >
                    Flagged
                  </b-form-checkbox>
                </b-form-group>
                <!-- Notes -->
                <b-form-group>
                  <b-form-textarea
                    v-model="checkpointDetailsModal.notes"
                    placeholder="Enter notes..."
                    rows="3"
                  />
                </b-form-group>
                <!-- Recheck Geolocation Button -->
                <b-form-group v-if="checkpointDetailsModal.show_recheck_button && checkpointDetailsModal.geo_required">
                  <b-alert
                    variant="warning"
                    show
                  >
                    <div class="d-flex justify-content-between align-items-center">
                      <span>You are more than 30 meters from the checkpoint location.</span>
                      <b-button
                        variant="outline-primary"
                        size="sm"
                        @click="recheckGeolocation"
                      >
                        Recheck Location
                      </b-button>
                    </div>
                  </b-alert>
                </b-form-group>
                <b-row class="mt-1 mb-1">
                  <b-col md="6">
                    <small class="text-muted">GPS Coordinates: </small><small v-html="checkpointDetailsModal.geo_lat && checkpointDetailsModal.geo_long ? `${checkpointDetailsModal.geo_lat}, ${checkpointDetailsModal.geo_long}` : checkpointDetailsModal.geo_required ? '<span class=\'text-danger font-weight-bold\'>N/A</span>' : 'N/A'" /><br>
                    <small class="text-muted">Geo Distance: </small><small v-html="checkpointDetailsModal.geo_distance && checkpointDetailsModal.geo_distance < 30 ? `${checkpointDetailsModal.geo_distance} m` : checkpointDetailsModal.geo_required ? `<span class='text-danger font-weight-bold'>${checkpointDetailsModal.geo_distance} m</span>` : 'N/A'" /><br>
                  </b-col>
                </b-row>
                <!-- Submit Button -->
                <b-row>
                  <b-col md="6">
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-2"
                      block
                      type="submit"
                    >
                      Submit
                    </b-button>
                  </b-col>
                  <b-col md="6">
                    <b-button
                      variant="outline-secondary"
                      class="mb-1 mb-sm-0"
                      block
                      @click="$bvModal.hide('checkpoint-modal')"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
              <!-- Photos and Images -->
              <b-card v-if="itemData && itemData.clientsite !== null && checkpointDetailsModal.name !== null && ($can('create', abilityRequired) || $can('update', abilityRequired))">
                <b-card-header>
                  <h5>
                    Photos / Images
                  </h5>
                </b-card-header>
                <b-card-body>
                  <!-- Button to trigger the file input -->
                  <div>
                    <b-row class="mb-2">
                      <b-col
                        v-if="$can('update', abilityRequired)"
                        md="2"
                      >
                        <b-button
                          variant="primary"
                          class="mb-1 mb-sm-0"
                          block
                          :disabled="preventDoubleClick"
                          @click="openCamera"
                        >
                          Take Picture
                        </b-button>
                      </b-col>
                      <b-col
                        v-if="$can('update', abilityRequired)"
                        md="2"
                      >
                        <b-button
                          variant="primary"
                          class="mb-1 mb-sm-0"
                          block
                          :disabled="preventDoubleClick"
                          @click="openImageSelector"
                        >
                          Add Image
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                  <!-- Images List -->
                  <section
                    v-if="docsAttachmentList"
                    class="grid-view"
                  >
                    <b-card
                      v-for="mediaItem in docsAttachmentList"
                      :id="mediaItem.filename"
                      :ref="mediaItem.filename"
                      :key="mediaItem.filename"
                      no-body
                    >
                      <div
                        v-if="mediaItem.filename.slice(-3) === 'jpg'"
                        class="item-img text-center"
                      >
                        <thumbs-lazy-image-component
                          class="card-img-top"
                          :src="mediaItem.access_url"
                          src-placeholder="@/assets/images/noImage.png"
                        />
                      </div>

                      <b-card-footer
                        v-if="mediaItem.filename.slice(-3) === 'jpg'"
                        class="pt-1 pb-1 border-dark"
                      >
                        <b-row>
                          <b-col>
                            <b-button
                              variant="primary"
                              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                              block
                              :disabled="preventDoubleClick"
                              @click="deleteImage(mediaItem)"
                            >
                              Delete Image
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-card-footer>
                    </b-card>
                  </section>
                  <!-- Hidden file input for accessing camera -->
                  <input
                    ref="cameraInput"
                    type="file"
                    accept="image/*"
                    capture="environment"
                    style="display: none;"
                    @change="handleImageChange"
                  >
                  <input
                    ref="imageInput"
                    type="file"
                    accept="image/*"
                    style="display: none;"
                    @change="handleImageChange"
                  >
                </b-card-body>
              </b-card>
            </b-modal>
            <!-- Incomplete Checkpoints List -->
            <b-row
              v-if="theCheckpoints"
              class="mt-2 mb-1 pt-1"
            >
              <b-col
                md="12"
              >
                <app-timeline>
                  <div
                    v-for="(checkpoint, index) in theCheckpoints"
                    :key="checkpoint.name"
                    :class="{
                      'cursor-pointer': itemData.status !== 'Paused' && ((thePatrolTemplate.patrol_in_order && index === 0) || !thePatrolTemplate.patrol_in_order),
                      'opacity-50': itemData.status === 'Paused'
                    }"
                    @click="handleCheckpointClick(checkpoint, index)"
                  >
                    <app-timeline-item
                      :variant="checkpoint.code_scanned ? 'success' : checkpoint.flagged ? 'danger' : 'warning'"
                      :icon="checkpoint.checkpoint_order === 0 ? 'PauseCircleIcon' : checkpoint.flagged ? 'AlertTriangleIcon' : 'MapPinIcon'"
                    >
                      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                        <h6>{{ checkpoint.name }}</h6>
                        <p
                          v-if="checkpoint.point_description"
                        >
                          {{ checkpoint.point_description }}
                        </p>
                        <small class="text-muted">GPS Coordinates: </small>
                        <small>{{ checkpoint.geo_lat && checkpoint.geo_long ? `${checkpoint.geo_lat}, ${checkpoint.geo_long}` : 'N/A' }}</small><br>
                      </div>
                    </app-timeline-item>
                  </div>
                </app-timeline>
              </b-col>
            </b-row>
            <!-- ***** Completed Checkpoints List ***** -->
            <b-row
              v-if="theCompletedCheckpoints"
              class="mt-2 mb-1 pt-1 border-top-secondary"
            >
              <b-col
                md="12"
              >
                <h4>Completed Checkpoints</h4>
              </b-col>
            </b-row>
            <!-- Completed Checkpoints List -->
            <b-row
              v-if="theCompletedCheckpoints"
              class="mt-2 mb-1 pt-1"
            >
              <b-col
                md="12"
              >
                <app-timeline>
                  <app-timeline-item
                    v-for="checkpoint in theCompletedCheckpoints"
                    :key="checkpoint.id"
                    :variant="checkpoint.code_scanned ? 'success' : checkpoint.flagged ? 'danger' : 'warning'"
                    :icon="checkpoint.checkpoint_order === 0 ? 'PauseCircleIcon' : checkpoint.flagged ? 'AlertTriangleIcon' : 'MapPinIcon'"
                  >
                    <div
                      class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                      @click="itemData.status !== 'Paused' && itemData.name !== 'Pause' ? handleCompletedCheckpointClick(checkpoint) : null"
                    >
                      <h6>{{ checkpoint.name }}</h6>
                      <small>{{ checkpoint.date_time }}</small>
                    </div>
                    <p v-html="checkpoint.notes ? checkpoint.notes.replace(/\n/g, '<br>') : ''" />
                    <p>
                      <template v-if="checkpoint.attachment_list">
                        <div
                          v-for="attachment in checkpoint.attachment_list"
                          :key="attachment.access_url"
                          class="item-img text-center"
                        >
                          <thumbs-lazy-image-component
                            width="500"
                            class="card-img-top"
                            :src="attachment.access_url"
                            src-placeholder="@/assets/images/noImage.png"
                          />
                        </div>
                      </template>
                    </p>
                  </app-timeline-item>

                </app-timeline>
              </b-col>
            </b-row>
            <!-- reorder checkpoints modal -->
            <b-modal
              id="modal-center"
              centered
              title="Vertically Centered"
              ok-only
              ok-title="Done"
            >
              <draggable
                v-model="itemData.checkpoints"
                class="list-group list-group-flush cursor-move"
                tag="ul"
                @end="onReorder"
              >
                <transition-group
                  type="transition"
                  name="flip-list"
                >
                  <b-list-group-item
                    v-for="listItem in itemData.checkpoints"
                    :key="listItem.nfc_tag"
                    tag="li"
                  >
                    <div class="d-flex">
                      <div class="ml-25">
                        <b-card-text class="font-weight-bold mb-0">
                          {{ listItem.name }}
                        </b-card-text>
                      </div>
                    </div>
                  </b-list-group-item>
                </transition-group>
              </draggable>
            </b-modal>
            <!-- Submit Button -->
            <div
              v-if="thePatrolTemplate && typeof itemData.status !== 'undefined' && itemData.status === 'Started'"
            >
              <b-row class="mt-3">
                <b-col
                  v-if="$can('create', abilityRequired)"
                  md="3"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    type="submit"
                    :disabled="preventDoubleClick"
                  >
                    Finalize {{ itemTypeName }}
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
    <!-- Show only on mobile devices -->
    <div v-if="$globals.isCordova">
      <!-- NFC related content -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { Storage, Auth } from 'aws-amplify'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import { required } from '@validations'
import {
  BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormGroup, BForm, BCardFooter, BFormCheckbox, BFormTextarea,
  BListGroupItem, BCardText, BImg,
} from 'bootstrap-vue'
import { Storage, Auth } from 'aws-amplify'
import {
  onUnmounted, ref, getCurrentInstance,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import draggable from 'vuedraggable'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import userPreferences from '@/store/userPreferences'
import storeModule from '../storeModule'
import clientStoreModule from '../../clients/clientStoreModule'
import useList from './useCheckPointsList'
import ThumbsLazyImageComponent from '../../thumbsLazyImageComponent'

// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// import ImageUploader from 'vue-image-upload-resize'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BCardFooter,
    BFormCheckbox,
    vSelect,
    draggable,
    BListGroupItem,
    BCardText,
    ThumbsLazyImageComponent,
    AppTimeline,
    AppTimelineItem,
    BImg,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      preventDoubleClick: false,
      nfcScanned: false,
      showImages: false,
    }
  },
  setup() {
    const instance = getCurrentInstance()
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userPrefs = userPreferences()

    const toast = useToast()
    const abilityRequired = 'patrols'
    const storeModuleName = 'app-patrols'
    const itemTypeName = 'Patrol'
    const printTitle = 'Patrols'
    const allowPrinting = false
    const allowApproval = false
    const filesDir = 'company/patrols'
    const maxFiles = 0 // Use 0 for no files otherwise specify max number
    const supportedFileTypes = ['*'] // Use * for all types, otherwise use an array of file extensions
    const maxImages = 0 // Use 0 for no images otherwise specify max number
    const supportedImageTypes = ['jpg']

    const clientsData = ref([])
    const clientOptions = ref([])
    const sitesData = ref([])
    const siteOptions = ref([])
    const patrolnameOptions = ref([])

    let theClientSite = null
    let clientsite = null
    let thePatrolName = null
    let SK = null
    const thePatrolTemplate = ref(null)
    const theCheckpoints = ref(null)
    const theCompletedCheckpoints = ref(null)
    const checkpointDetailsModal = ref({
      name: null,
      point_description: null,
    })

    const checkpointBtnLable = 'Add Checkpoint'
    const checkpointProcessType = 'Adding'
    const geoRequired = false
    const checkpointName = ref(null)
    const pointDescription = ref(null)
    const checkpointOrder = ref(null)
    const geoLocationLat = ref(null)
    const geoLocationLong = ref(null)
    const docsAttachmentList = ref(null)
    const nfcTag = ref(null)

    // const itemData = { checkpoints: [] }
    const itemData = ref({})
    console.log('router.currentRoute.params: ')
    console.log(router.currentRoute.params)

    // Register module
    if (!store.hasModule(storeModuleName)) store.registerModule(storeModuleName, storeModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(storeModuleName)) store.unregisterModule(storeModuleName)
    })

    // Get Client Sites function - used when a client is selected
    function getClientSites(clientname) {
      console.log(clientname)
      console.log('fetchClientSites')
      // toast({
      //   component: ToastificationContent,
      //   position: 'top-right',
      //   props: {
      //     title: 'Getting Site List',
      //     icon: 'CheckIcon',
      //     variant: 'success',
      //   },
      // })
      if (ability.can('read', 'clients')) {
        const qvars = {
          forceRefresh: false,
          q: null,
          checkpointsperPage: 0,
          page: 1,
          sortBy: 'sitename',
          sortDesc: true,
          status: null,
          clientname,
        }
        console.log(qvars)
        store
          .dispatch('app-client/fetchClientSites', qvars)
          .then(response => {
            console.log(response)
            console.log(sitesData.value)
            sitesData.value = response.data.clientSites
            siteOptions.value = sitesData.value
            sitesData.value.forEach((item, index) => {
              if (item.is_active) {
                siteOptions.value[index].label = item.sitename
                siteOptions.value[index].value = item.sitename
              }
            })
            // If only one site, automatically select it
            if (siteOptions.value.length === 1) {
              itemData.value.sitename = siteOptions.value[0].value
            }
            siteOptions.value.unshift({ label: '<select>', value: '' })
            console.log('this.sitesData')
            console.log(sitesData)
            console.log('this.siteOptions')
            console.log(siteOptions)
            if (userPrefs.getUserPref('patrolsSiteFilter') !== null) {
              itemData.value.sitename = userPrefs.getUserPref('patrolsSiteFilter')
            }
          })
          .catch(error => {
            console.log(error)
            store.state.clients.fetchingClientSitesList = false
            if (typeof error.response !== 'undefined' && error.response.status === 404) {
              sitesData.value = []
            }
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching Client Sites list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    // Get Client Sites function - used when a client is selected
    function getClientSitePatrolTemplates(clientname, sitename) {
      console.log('getClientSitePatrolTemplates')
      console.log(clientname)
      console.log(sitename)
      // toast({
      //   component: ToastificationContent,
      //   position: 'top-right',
      //   props: {
      //     title: 'Getting Patrol Template List',
      //     icon: 'CheckIcon',
      //     variant: 'success',
      //   },
      // })
      if (ability.can('read', 'patrols')) {
        const qvars = {
          forceRefresh: false,
          q: null,
          page: 1,
          sortBy: 'patrolname',
          sortDesc: true,
          status: null,
          clientname,
          sitename,
        }
        console.log(qvars)
        store
          .dispatch('app-patrols/fetchTemplates', qvars)
          .then(response => {
            console.log(response)
            // Loop through response and add to patrolnameOptions
            response.data.forEach(item => {
              clientsite = item.clientsite
              patrolnameOptions.value.push({ label: item.patrolname, value: item.patrolname })
            })
            // If only one template exists, automatically select it
            if (patrolnameOptions.value.length === 1) {
              itemData.value.patrolname = patrolnameOptions.value[0].value
              this.handleSelectPatrolTemplate(patrolnameOptions.value[0].value)
            }
            theClientSite = clientsite
            console.log('patrolnameOptions')
            console.log(patrolnameOptions)
          })
          .catch(error => {
            console.log(error)
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching Patrol Templates list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    // Get Client Sites function - used when a client is selected
    function getPatrolTemplate(thePatrolname) {
      console.log('getPatrolTemplate')
      console.log(clientsite)
      console.log(thePatrolname)
      // toast({
      //   component: ToastificationContent,
      //   position: 'top-right',
      //   props: {
      //     title: 'Getting Patrol Template Details',
      //     icon: 'CheckIcon',
      //     variant: 'success',
      //   },
      // })
      if (ability.can('read', 'patrols')) {
        const qvars = {
          forceRefresh: false,
          q: null,
          page: 1,
          sortBy: 'patrolname',
          sortDesc: true,
          status: null,
          clientsite,
          patrolname: thePatrolname,
        }
        console.log(qvars)
        store
          .dispatch('app-patrols/fetchTemplate', qvars)
          .then(response => {
            console.log(response)
            thePatrolTemplate.value = response.data
            // Setup the itemData with the template data
            itemData.value.status = 'New'
            console.log('thePatrolTemplate')
            console.log(thePatrolTemplate)
          })
          .catch(error => {
            console.log(error)
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching Patrol Templates list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    if (router.currentRoute.params.patrolname !== undefined && router.currentRoute.params.patrolname !== null
      && router.currentRoute.params.clientsite !== undefined && router.currentRoute.params.clientsite !== null
      && router.currentRoute.params.SK !== undefined && router.currentRoute.params.SK !== null) {
      console.log('Found Params in route')
      theClientSite = router.currentRoute.params.clientsite
      clientsite = router.currentRoute.params.clientsite
      thePatrolName = router.currentRoute.params.patrolname
      SK = router.currentRoute.params.SK
      getPatrolTemplate(thePatrolName)
    }

    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })

    // Get client list
    if (ability.can('read', 'clients')) {
      // toast({
      //   component: ToastificationContent,
      //   position: 'top-right',
      //   props: {
      //     title: 'Getting Client List',
      //     icon: 'CheckIcon',
      //     variant: 'success',
      //   },
      // })
      const qvars = {
        forceRefresh: false,
        q: null,
        checkpointsperPage: 0,
        page: 1,
        sortBy: 'display_name',
        sortDesc: true,
        status: null,
        service: null,
      }
      console.log(qvars)
      store
        .dispatch('app-client/fetchClients', qvars)
        .then(response => {
          clientsData.value = response.data
          clientsData.value.clients.forEach(item => {
            clientOptions.value.push({ label: item.display_name, value: item.clientname })
          })
          console.log('clientsData')
          console.log(clientsData)
          console.log('clientOptions')
          console.log(clientOptions)
          // set the default client based on the user preferences
          if (userPrefs.getUserPref('patrolsClientFilter') !== null) {
            itemData.value.clientname = userPrefs.getUserPref('patrolsClientFilter')
            instance.proxy.checkUserPrefs()
          }
        })
        .catch(error => {
          console.log(error) // No current user
          if (typeof error.response !== 'undefined' && error.response.status === 404) {
            clientsData.value = undefined
          }
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching clients list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const {
      fetchListItems,
      checkpointsTableColumns,
      checkpointsperPage,
      checkpointsCurrentPage,
      totalCheckpoints,
      theList,
      theListComplete,
      dataMeta,
      checkpointsperPageOptions,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,
    } = useList(itemData)

    return {
      userPrefs,
      userData,
      abilityRequired,
      storeModuleName,
      itemTypeName,
      allowPrinting,
      allowApproval,
      printTitle,
      filesDir,
      maxFiles,
      supportedFileTypes,
      maxImages,
      supportedImageTypes,
      theClientSite,
      clientsite,
      thePatrolName,
      SK,
      thePatrolTemplate,
      theCheckpoints,
      theCompletedCheckpoints,
      checkpointDetailsModal,
      clientsData,
      clientOptions,
      siteOptions,
      patrolnameOptions,
      sitesData,
      getClientSites,
      getClientSitePatrolTemplates,
      getPatrolTemplate,

      checkpointBtnLable,
      checkpointProcessType,
      geoRequired,
      checkpointName,
      pointDescription,
      checkpointOrder,
      geoLocationLat,
      geoLocationLong,
      docsAttachmentList,
      nfcTag,

      itemData,
      fetchListItems,
      checkpointsTableColumns,
      checkpointsperPage,
      checkpointsCurrentPage,
      totalCheckpoints,
      theList,
      theListComplete,
      dataMeta,
      checkpointsperPageOptions,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,
    }
  },
  created() {
    // Get the item details if patrolname is passed in
    if (this.theClientSite === null) {
      this.itemData = {}
      this.itemData.patrolname = null
      this.itemData.checkpoints = []
    } else {
      this.itemData.status = 'Started'
      this.getExistingItemData()
    }
  },
  methods: {
    checkUserPrefs() {
      if (this.userPrefs.getUserPref('patrolsClientFilter') !== null) {
        this.itemData.clientname = this.userPrefs.getUserPref('patrolsClientFilter')
        console.log('this.itemData.clientname', this.itemData.clientname)
        this.handleSelectClient(this.itemData.clientname)
      }
      if (this.userPrefs.getUserPref('patrolsSiteFilter') !== null) {
        this.itemData.sitename = this.userPrefs.getUserPref('patrolsSiteFilter')
        console.log('this.itemData.sitename', this.itemData.sitename)
        this.handleSelectSite(this.itemData.sitename)
      }
    },
    generateUUID() {
      return crypto.randomUUID()
    },
    getExistingItemData() {
      console.log('getExistingItemData')
      if (this.theClientSite !== null && ability.can('update', this.abilityRequired)) {
        console.log('getting item data')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Getting ${this.itemTypeName} Data`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        const qvars = {
          abilityRequired: this.abilityRequired,
          SK: this.SK,
          clientsite: this.clientsite,
        }
        store.dispatch(`${this.storeModuleName}/fetchItem`, qvars)
          .then(response => {
            console.log(response)
            this.itemData = response.data
            this.reStartPatrol()
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error Getting ${this.itemTypeName}.`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
    async handleImageLoad(mediaItem) {
      console.log('handleImageLoad')
      console.log(mediaItem)
      try {
        const resolvedUrl = await this.resolveImageUrl(mediaItem.access_url)
        if (resolvedUrl) {
          // Update the mediaItem with the resolved URL
          this.$set(mediaItem, 'access_url', resolvedUrl)
        }
      } catch (error) {
        console.error('Error loading image:', error)
      }
    },
    async resolveImageUrl(url) {
      console.log('resolveImageUrl')
      console.log(url)
      try {
        const result = await Storage.get(url, {
          download: false,
          bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
          region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
          identityPoolId: 'ca-central-1_Ao5uWjpYj',
          custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
        })
        console.log(result)
        return result
      } catch (error) {
        console.error('Error resolving image URL:', error)
        return null
      }
    },
    handleSelectClient(val) {
      console.log('handleSelectClient')
      console.log(val)
      this.clientsData.clients.forEach(item => {
        if (item.clientname === val) {
          this.itemData.client_display_name = item.display_name
        }
      })
      this.getClientSites(val)
    },
    handleSelectSite(val) {
      console.log('handleSelectClientSite')
      console.log(val)
      this.getClientSitePatrolTemplates(this.itemData.clientname, val)
    },
    handleSelectPatrolTemplate(val) {
      console.log('handleSelectPatrolTemplate')
      console.log(val)
      this.itemData.patrolname = val
      this.getPatrolTemplate(val)
    },
    handleBackToList() {
      this.$router.push({ name: 'apps-patrols' })
    },
    finalizePatrol() {
      this.validateForm(true)
    },
    validateForm(finalize = false) {
      console.log('validateForm')
      let proceedWithSave = false
      let dispatchLocation = ''
      let toastText = ''
      let processType = null
      if (this.theClientSite === null && ability.can('create', this.abilityRequired)) {
        console.log('adding patrol')
        processType = 'add'
        proceedWithSave = true
        dispatchLocation = `${this.storeModuleName}/submitPatrol`
        toastText = 'Add'
      } else if (this.theClientSite !== null && ability.can('update', this.abilityRequired)) {
        console.log('editing patrol')
        processType = 'update'
        proceedWithSave = true
        dispatchLocation = `${this.storeModuleName}/submitPatrol`
        toastText = 'Updat'
      }
      // Validate Checkpoints
      this.itemData.checkpoints = this.theCompletedCheckpoints
      // Check if all checkpoints are completed
      if (this.theCheckpoints.length === 0) {
        this.itemData.unfinished_checkpoints = false
      } else {
        this.itemData.unfinished_checkpoints = true
      }
      // Check to ensure that patrol_in_order exists in itemData
      this.itemData.patrol_in_order = this.thePatrolTemplate.patrol_in_order
      // determine if any are flagged
      this.theCompletedCheckpoints.forEach(checkpoint => {
        if (checkpoint.flagged) {
          this.itemData.contains_flag = true
        }
      })
      // determine if any are outside of 30 metres
      this.itemData.geo_distance_flag = false
      this.theCompletedCheckpoints.forEach(checkpoint => {
        if (!checkpoint.geo_distance || (checkpoint.geo_distance > 30 && checkpoint.geo_required)) {
          this.itemData.geo_distance_flag = true
        }
      })
      // Set the end date time
      if (finalize) {
        this.itemData.end_date_time = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      }

      console.log('this.itemData', this.itemData)
      if (proceedWithSave) {
        console.log(this.itemData)
        console.log(JSON.parse(JSON.stringify(this.itemData)))
        if (proceedWithSave) {
          this.$refs.observer.validate().then(success => {
            if (success) {
              this.preventDoubleClick = true
              console.log('form validated')
              console.log(this.itemData)
              // Create a clean dataset with only the data being updated
              const cleanData = JSON.parse(JSON.stringify(this.itemData))
              // delete cleanData.abilities
              console.log(cleanData)
              console.log(dispatchLocation)
              // Call backend
              store.dispatch(`${dispatchLocation}`, cleanData)
                .then(response => {
                  this.preventDoubleClick = false
                  console.log(response)
                  if (processType === 'add') {
                    this.itemData.SK = response.SK
                    this.itemData.clientsite = response.clientsite
                    this.theClientSite = this.itemData.clientsite
                  }
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `${this.itemTypeName} ${toastText}ed`,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  if (finalize) {
                    this.handleBackToList()
                  }
                })
                .catch(error => {
                  console.log(error)
                  this.preventDoubleClick = false
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Error ${toastText}ing ${this.itemTypeName}.`,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: 'An error occurred while saving the patrol. Please try again.',
                    },
                  })
                })
            }
          })
        }
      }
    },
    handleStartPatrol() {
      console.log('handleStartPatrol')
      console.log(this.itemData)
      this.$set(this.itemData, 'start_date_time', moment(new Date()).format('YYYY-MM-DD HH:mm:ss'))
      this.$set(this.itemData, 'status', 'Started')
      this.itemData.time_zone = new Intl.DateTimeFormat().resolvedOptions().timeZone
      this.itemData.end_date_time = null
      this.itemData.flagged_email_list = this.thePatrolTemplate.flagged_email_list
      this.itemData.submitted_email_list = this.thePatrolTemplate.submitted_email_list
      this.itemData.username = this.userData.username
      this.itemData.user_display_name = this.userData.fullName
      this.itemData.patrolname = this.thePatrolTemplate.patrolname
      this.itemData.clientname = this.thePatrolTemplate.clientname
      this.itemData.client_display_name = this.thePatrolTemplate.client_display_name
      this.itemData.clientsite = this.thePatrolTemplate.clientsite
      this.itemData.sitename = this.thePatrolTemplate.sitename
      this.itemData.contains_flag = false
      this.itemData.unfinished_checkpoints = false
      this.$set(this, 'theCheckpoints', this.thePatrolTemplate.checkpoints)
      this.$set(this, 'theCompletedCheckpoints', [])
    },
    reStartPatrol() {
      console.log('reStartPatrol')
      console.log(this.itemData)
      this.$set(this.itemData, 'status', 'Restarting')
      this.$set(this, 'theCheckpoints', this.thePatrolTemplate.checkpoints)
      this.$set(this, 'theCompletedCheckpoints', [])
      // Loop through the itemData.checkpoints and add them to the theCompletedCheckpoints array
      this.itemData.checkpoints.forEach(checkpoint => {
        this.handleCompletedCheckpointClick(checkpoint)
      })
      this.$set(this.itemData, 'status', 'Started')
    },
    handlePausePatrol() {
      // Update status to Paused
      this.$set(this.itemData, 'status', 'Paused')

      let geoLat = ''
      let geoLong = ''

      // Only get GPS coordinates if Cordova is available
      if (this.$globals.isCordova) {
        try {
          navigator.geolocation.getCurrentPosition(
            position => {
              geoLat = position.coords.latitude.toString()
              geoLong = position.coords.longitude.toString()
            },
            error => {
              console.error('Error getting GPS coordinates:', error)
            },
          )
        } catch (error) {
          console.error('Error getting GPS coordinates:', error)
        }
      }

      // Create pause checkpoint object
      const pauseCheckpoint = {
        checkpoint_order: 0,
        name: 'Pause',
        date_time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        geo_lat: geoLat,
        geo_long: geoLong,
        notes: 'Patrol Paused',
        flagged: false,
        geo_required: false,
      }

      // Call handleIncompleteCheckpointClick with pause data
      this.handleIncompleteCheckpointClick(pauseCheckpoint)
    },
    handleResumePatrol() {
      if (this.itemData.status === 'Paused') {
        // open the modal
        this.$bvModal.show('checkpoint-modal')
      }
    },
    getGeoLocation(checkpoint) {
      console.log('Getting GPS')
      navigator.geolocation.getCurrentPosition(
        position => {
          console.log('GPS Position', position)
          this.checkpointDetailsModal.geo_lat = position.coords.latitude.toString()
          this.checkpointDetailsModal.geo_long = position.coords.longitude.toString()
          console.log('Geo Required', checkpoint.geo_required)
          if (checkpoint.geo_required) {
            // Calculate distance between current position and checkpoint position using Haversine formula
            const R = 6371e3 // Earth's radius in metres
            const φ1 = (position.coords.latitude * Math.PI) / 180
            const φ2 = (parseFloat(checkpoint.geo_location_lat) * Math.PI) / 180
            const Δφ = ((parseFloat(checkpoint.geo_location_lat) - position.coords.latitude) * Math.PI) / 180
            const Δλ = ((parseFloat(checkpoint.geo_location_long) - position.coords.longitude) * Math.PI) / 180
            console.log('Δφ', Δφ)
            console.log('Δλ', Δλ)
            console.log('φ1', φ1)
            console.log('φ2', φ2)

            const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2)
                + Math.cos(φ1) * Math.cos(φ2)
                * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
            const distance = R * c
            console.log('distance', distance)
            this.checkpointDetailsModal.geo_distance = Math.round(distance)

            if (distance > 30) {
              // More than 30 metres away
              // this.$bvModal.hide('checkpoint-modal')
              this.$set(this.checkpointDetailsModal, 'show_recheck_button', true)
              this.$bvToast.toast('You must be within 30 metres of the checkpoint location', {
                title: 'Location Check Failed',
                variant: 'danger',
                solid: true,
              })
            } else {
              this.$set(this.checkpointDetailsModal, 'show_recheck_button', false)
            }
          }
        },
        error => {
          console.error('Error getting location:', error)
          this.$bvToast.toast('Unable to get current location. Please ensure location services are enabled.', {
            title: 'Location Error',
            variant: 'danger',
            solid: true,
          })
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        },
      )
    },
    handleIncompleteCheckpointClick(checkpoint) {
      console.log('handleIncompleteCheckpointClick')
      console.log(checkpoint)
      this.nfcScanned = false
      this.showImages = false
      this.$set(this, 'checkpointDetailsModal', checkpoint)
      this.$set(this.checkpointDetailsModal, 'checkpoint_order', checkpoint.checkpoint_order)
      this.$set(this.checkpointDetailsModal, 'name', checkpoint.name)
      this.$set(this.checkpointDetailsModal, 'flagged', false)
      this.$set(this.checkpointDetailsModal, 'notes', '')
      this.$set(this.checkpointDetailsModal, 'date_time', moment(new Date()).format('YYYY-MM-DD HH:mm:ss'))
      this.$set(this.checkpointDetailsModal, 'attachment_list', [])
      this.$set(this.checkpointDetailsModal, 'code_scanned', false)
      this.$set(this.checkpointDetailsModal, 'geo_lat', '')
      this.$set(this.checkpointDetailsModal, 'geo_long', '')
      this.$set(this.checkpointDetailsModal, 'geo_required', checkpoint.geo_required)
      this.$set(this.checkpointDetailsModal, 'geo_location_lat', checkpoint.geo_location_lat ? checkpoint.geo_location_lat.toString() : '')
      this.$set(this.checkpointDetailsModal, 'geo_location_long', checkpoint.geo_location_long ? checkpoint.geo_location_long.toString() : '')
      this.$set(this.checkpointDetailsModal, 'show_recheck_button', false)
      // Set the checkpoint name to the checkpoint name
      this.checkpointOrder = checkpoint.checkpoint_order
      this.checkpointName = checkpoint.name
      this.pointDescription = checkpoint.point_description || ''
      this.geoLocationLat = checkpoint.geo_location_lat
      this.geoLocationLong = checkpoint.geo_location_long
      this.nfcTag = checkpoint.nfc_tag
      this.docsAttachmentList = checkpoint.attachment_list
      // use Cordova GPS to get the geo location
      console.log('Check for Cordova')
      console.log(this.$globals.isCordova)
      if (this.$globals.isCordova && checkpoint.name !== 'Pause') {
        this.getGeoLocation(checkpoint)
        console.log('Listen for NFC')
        this.readNFC(checkpoint)
      }
      if (this.itemData.status === 'Restarting') {
        this.handleCheckpointSubmit()
      } else {
        this.$bvModal.show('checkpoint-modal')
        setTimeout(() => {
          this.showImages = true
        }, 1000)
      }
    },
    handleCompletedCheckpointClick(checkpoint) {
      console.log('handleCompletedCheckpointClick')
      console.log(checkpoint)
      this.nfcScanned = false
      this.showImages = false
      this.$set(this, 'checkpointDetailsModal', checkpoint)
      this.$set(this.checkpointDetailsModal, 'checkpoint_order', checkpoint.checkpoint_order)
      this.$set(this.checkpointDetailsModal, 'name', checkpoint.name)
      this.$set(this.checkpointDetailsModal, 'flagged', checkpoint.flagged)
      this.$set(this.checkpointDetailsModal, 'notes', checkpoint.notes)
      this.$set(this.checkpointDetailsModal, 'date_time', checkpoint.date_time)
      this.$set(this.checkpointDetailsModal, 'attachment_list', checkpoint.attachment_list)
      this.$set(this.checkpointDetailsModal, 'code_scanned', checkpoint.code_scanned)
      this.$set(this.checkpointDetailsModal, 'geo_lat', checkpoint.geo_lat ? checkpoint.geo_lat.toString() : '')
      this.$set(this.checkpointDetailsModal, 'geo_long', checkpoint.geo_long ? checkpoint.geo_long.toString() : '')
      this.$set(this.checkpointDetailsModal, 'geo_required', checkpoint.geo_required)
      this.$set(this.checkpointDetailsModal, 'geo_location_lat', checkpoint.geo_location_lat ? checkpoint.geo_location_lat.toString() : '')
      this.$set(this.checkpointDetailsModal, 'geo_location_long', checkpoint.geo_location_long ? checkpoint.geo_location_long.toString() : '')
      this.$set(this.checkpointDetailsModal, 'nfc_tag', checkpoint.nfc_tag)
      this.$set(this.checkpointDetailsModal, 'point_description', checkpoint.point_description || '')
      this.$set(this.checkpointDetailsModal, 'show_recheck_button', false)
      // Set the checkpoint name to the checkpoint name
      this.checkpointOrder = checkpoint.checkpoint_order
      this.checkpointName = checkpoint.name
      this.pointDescription = checkpoint.point_description || ''
      this.geoLocationLat = checkpoint.geo_location_lat
      this.geoLocationLong = checkpoint.geo_location_long
      this.nfcTag = checkpoint.nfc_tag
      this.docsAttachmentList = checkpoint.attachment_list
      // use Cordova GPS to get the geo location
      console.log('Check for Cordova')
      console.log(this.$globals.isCordova)
      if (this.$globals.isCordova && checkpoint.name !== 'Pause') {
        this.getGeoLocation(checkpoint)
        console.log('Listen for NFC')
        this.readNFC(checkpoint)
      }
      if (this.itemData.status === 'Restarting') {
        this.handleCheckpointSubmit()
      } else {
        this.$bvModal.show('checkpoint-modal')
        setTimeout(() => {
          this.showImages = true
        }, 1000)
      }
    },
    handleCheckpointClick(checkpoint, index) {
      // Don't allow clicks if patrol is paused
      if (this.itemData.status === 'Paused') {
        return
      }

      // If patrol_in_order is true, only allow first checkpoint
      console.log('this.thePatrolTemplate.patrol_in_order')
      console.log(this.thePatrolTemplate.patrol_in_order)
      console.log('index')
      console.log(index)
      if (this.thePatrolTemplate.patrol_in_order && index !== 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Checkpoints must be completed in order',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      // Open the checkpoint modal
      this.handleIncompleteCheckpointClick(checkpoint)
    },
    handleCheckpointSubmit() {
      // Create the checkpoint details
      let completedCheckpoint = {}
      if (this.checkpointDetailsModal.name === 'Pause') {
        completedCheckpoint = {
          checkpoint_order: this.checkpointDetailsModal.checkpoint_order,
          name: this.checkpointDetailsModal.name,
          date_time: this.checkpointDetailsModal.date_time,
          geo_lat: this.checkpointDetailsModal.geo_lat ? this.checkpointDetailsModal.geo_lat.toString() : '',
          geo_long: this.checkpointDetailsModal.geo_long ? this.checkpointDetailsModal.geo_long.toString() : '',
          notes: this.checkpointDetailsModal.notes,
        }
      } else {
        completedCheckpoint = {
          checkpoint_order: this.checkpointDetailsModal.checkpoint_order,
          name: this.checkpointDetailsModal.name,
          date_time: this.checkpointDetailsModal.date_time,
          geo_lat: this.checkpointDetailsModal.geo_lat ? this.checkpointDetailsModal.geo_lat.toString() : '',
          geo_long: this.checkpointDetailsModal.geo_long ? this.checkpointDetailsModal.geo_long.toString() : '',
          geo_distance: this.checkpointDetailsModal.geo_distance ? this.checkpointDetailsModal.geo_distance.toString() : '',
          notes: this.checkpointDetailsModal.notes,
          flagged: this.checkpointDetailsModal.flagged,
          code_scanned: this.checkpointDetailsModal.code_scanned,
          geo_location_lat: this.checkpointDetailsModal.geo_location_lat ? this.checkpointDetailsModal.geo_location_lat.toString() : '',
          geo_location_long: this.checkpointDetailsModal.geo_location_long ? this.checkpointDetailsModal.geo_location_long.toString() : '',
          geo_required: this.checkpointDetailsModal.geo_required,
          attachment_list: JSON.parse(JSON.stringify(this.docsAttachmentList)),
          nfc_tag: this.checkpointDetailsModal.nfc_tag,
        }
      }

      // Add the checkpoint to completed checkpoints array and force UI update
      // Find if this checkpoint already exists in completed checkpoints
      const existingIndex = this.theCompletedCheckpoints.findIndex(
        cp => cp.checkpoint_order === completedCheckpoint.checkpoint_order && cp.name === completedCheckpoint.name,
      )
      // for insert of pause checkpoints
      if (completedCheckpoint.name === 'Pause') {
        // Add new pause checkpoint
        this.$set(this.theCompletedCheckpoints, this.theCompletedCheckpoints.length, completedCheckpoint)
      } else if (existingIndex >= 0) {
        // Update existing checkpoint
        this.$set(this.theCompletedCheckpoints, existingIndex, completedCheckpoint)
      } else {
        // Add new checkpoint
        this.$set(this.theCompletedCheckpoints, this.theCompletedCheckpoints.length, completedCheckpoint)
      }
      // If this was a regular checkpoint (not a pause), remove it from incomplete checkpoints
      if (completedCheckpoint.name !== 'Pause') {
        this.theCheckpoints = this.theCheckpoints.filter(
          checkpoint => checkpoint.name !== completedCheckpoint.name,
        )
      }

      // If this was a pause checkpoint being completed, update status back to Started
      if (completedCheckpoint.name === 'Pause') {
        this.$set(this.itemData, 'status', 'Started')
      }

      // Close the modal
      this.$bvModal.hide('checkpoint-modal')

      // Reset the checkpoint details modal
      this.checkpointDetailsModal = {
        name: null,
        point_description: null,
        flagged: false,
        notes: '',
      }
      this.preventDoubleClick = false
      console.log('Listen for NFC')
      this.stopReadNFC()
      // submit Patrol
      if (this.itemData.status !== 'Restarting') {
        this.validateForm()
      }
    },
    onReorder() {
      console.log('Reordered items')
      const finalcheckpoints = []
      this.itemData.checkpoints.forEach((item, index) => {
        const tmp = item
        tmp.checkpoint_order = index + 1
        finalcheckpoints.push(tmp)
      })
      this.itemData.checkpoints = finalcheckpoints
    },
    resetCheckpointForm() {
      console.log('resetCheckpointForm')
      this.checkpointOrder = null
      this.checkpointName = null
      this.pointDescription = null
      this.geoLocationLat = null
      this.geoLocationLong = null
      this.docsAttachmentList = null
      this.nfcTag = null
      this.checkpointBtnLable = 'Add Checkpoint'
      this.checkpointProcessType = 'Adding'
    },
    openCamera() {
      // Trigger the file input click event
      this.$refs.cameraInput.click()
    },
    openImageSelector() {
      // Trigger the file input click event
      this.$refs.imageInput.click()
    },
    handleImageChange(event) {
      console.log('handleImageChange')
      console.log('this.itemData', this.itemData)
      console.log('this.itemData.patrolname', this.itemData.patrolname)
      console.log('this.checkpointName', this.checkpointName)
      console.log('this.itemData.clientname', this.itemData.clientname)
      console.log('this.itemData.sitename', this.itemData.sitename)
      const file = event.target.files[0]
      const supportedExtensions = ['jpg', 'jpeg', 'png', 'tiff', 'gif']
      if (file) {
        const parts = file.name.split('.')
        if (parts.length === 1) {
          throw new Error('File not valid')
        }
        const fileExtension = parts.pop().toLowerCase()
        if (supportedExtensions.includes(fileExtension)) {
          console.log('Client Site Name:', this.theClientSite)
          console.log('Checkpoint Name:', this.checkpointName)
          const theFileName = `Patrol_Image_${this.itemData.patrolname.replace(' ', '')}_${this.checkpointName.replace(' ', '')}_${moment(Date()).format('YYYYMMDDTHH-mm-ss')}.jpg`
          const theFullFileName = `patrols/${this.itemData.clientname.replace(' ', '')}/${this.itemData.sitename.replace(' ', '')}/${theFileName}`
          this.preventDoubleClick = true
          Storage.put(theFullFileName, file, {
            bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
            region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
            identityPoolId: 'ca-central-1_Ao5uWjpYj',
            custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
          })
            .then(result => {
              this.preventDoubleClick = false
              this.docsAttachmentList.push({
                filename: theFileName,
                access_url: result.key,
                original_ext: fileExtension,
              })
            })
            .catch(err => {
              console.log(err)
              this.preventDoubleClick = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error Uploading File.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invalid File Type. Must be JPG, TIFF, PNG, or GIF.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    deleteImage(item) {
      console.log('deleteimage')
      console.log(item)
      this.preventDoubleClick = true
      Storage.remove(item.access_url, {
        bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
        region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
        identityPoolId: 'ca-central-1_Ao5uWjpYj',
        custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      })
        .then(result => {
          this.preventDoubleClick = false
          console.log(result)
          console.log(this.reportData)
          // Update the report data deleting the item from the attachment list
          this.docsAttachmentList.forEach((item1, index) => {
            if (item1.access_url === item.access_url) {
              this.docsAttachmentList.splice(index, 1)
            }
          })
        })
        .catch(err => {
          console.log(err)
          this.preventDoubleClick = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Deleting File.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    decryptString(encryptedBase64) {
      // Same key as used for encryption
      const key = 'kldgf8990sdljkg2n34r'
      // Decode from Base64
      const encrypted = atob(encryptedBase64)
      let decrypted = ''
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < encrypted.length; i++) {
        // XOR each character with corresponding character from key
        // eslint-disable-next-line no-bitwise
        const charCode = encrypted.charCodeAt(i) ^ key.charCodeAt(i % key.length)
        decrypted += String.fromCharCode(charCode)
      }
      return decrypted
    },
    readNFC(checkpoint) {
      console.log('readNFC')
      console.log(checkpoint)
      if (!this.$globals.isCordova) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'NFC is only available on mobile devices',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      if (!this.$globals.nfc || !this.$globals.ndef) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'NFC is not available on this device',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      this.$globals.replaceCurrentNdefListener(
        nfcEvent => {
          try {
            const { tag } = nfcEvent
            console.log('tag', tag)
            const { ndefMessage } = tag
            if (ndefMessage && ndefMessage.length > 0) {
              // Get the first record from the message
              const record = ndefMessage[0]
              console.log('record', record)
              // Convert payload to string directly
              let payloadStr = ''
              const { payload } = record
              // Skip first 3 bytes (language code) and convert to string
              // eslint-disable-next-line no-plusplus
              for (let i = 3; i < payload.length; i++) {
                payloadStr += String.fromCharCode(payload[i])
              }
              console.log('payloadStr:', payloadStr)
              // Decrypt the data
              const decryptedData = this.decryptString(payloadStr)
              console.log('Decrypted NFC content:', decryptedData)
              console.log('checkpoint.nfc_tag:', checkpoint.nfc_tag)
              if (decryptedData.trim().toString() === checkpoint.nfc_tag.trim().toString()) {
                this.nfcScanned = true
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'NFC Tag Read Successfully',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              } else {
                this.nfcScanned = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'NFC Tag does not match',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (error) {
            this.nfcScanned = false
            console.error('Error processing NFC tag:', error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error processing NFC tag: ${error.message}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        },
        () => {
          console.log('Ready to read NFC tag')
        },
        error => {
          this.nfcScanned = false
          console.error('NFC Error:', error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Error reading NFC: ${error}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        },
      )
    },
    stopReadNFC() {
      console.log('stopReadNFC')
      if (this.$globals.nfc) {
        console.log('Removing NFC Listener')
        this.$globals.nfc.removeNdefListener()
      }
    },
    async checkAndRequestGeolocation() {
      // First check if we're in a Cordova environment
      if (window.cordova && window.cordova.plugins.permissions) {
        const permission = window.cordova.plugins.permissions.ACCESS_FINE_LOCATION
        try {
          // Check permission status
          const status = await new Promise((resolve, reject) => {
            window.cordova.plugins.permissions.checkPermission(permission, resolve, reject)
          })

          if (!status.hasPermission) {
            // Request permission
            await new Promise((resolve, reject) => {
              window.cordova.plugins.permissions.requestPermission(permission, resolve, reject)
            })
          }

          // Get position after permissions are granted
          return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, {
              enableHighAccuracy: true,
              timeout: 10000,
              maximumAge: 0,
            })
          })
        } catch (error) {
          console.error('Permission error:', error)
          this.$bvToast.toast('Please enable location permissions in your device settings.', {
            title: 'Location Permission Required',
            variant: 'danger',
            solid: true,
          })
          throw error
        }
      } else {
        // Fall back to regular geolocation
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0,
          })
        })
      }
    },
    async getLocation(checkpoint) {
      try {
        const position = await this.checkAndRequestGeolocation()
        console.log('GPS Position', position)
        this.checkpointDetailsModal.geo_lat = position.coords.latitude
        this.checkpointDetailsModal.geo_long = position.coords.longitude
        // Your existing distance calculation code...
        if (checkpoint.geo_required) {
          const R = 6371e3
          const φ1 = (position.coords.latitude * Math.PI) / 180
          const φ2 = (parseFloat(checkpoint.geo_location_lat) * Math.PI) / 180
          const Δφ = ((parseFloat(checkpoint.geo_location_lat) - position.coords.latitude) * Math.PI) / 180
          const Δλ = ((parseFloat(checkpoint.geo_location_long) - position.coords.longitude) * Math.PI) / 180

          const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2)
              + Math.cos(φ1) * Math.cos(φ2)
              * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
          const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
          const distance = R * c

          if (distance > 30) {
            this.$bvModal.hide('checkpoint-modal')
            this.$bvToast.toast('You must be within 5 metres of the checkpoint location', {
              title: 'Location Check Failed',
              variant: 'danger',
              solid: true,
            })
          }
        }
      } catch (error) {
        console.error('Location error:', error)
        this.$bvToast.toast('Unable to get location. Please check your location settings.', {
          title: 'Location Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
    // Add a new method to recheck geolocation
    recheckGeolocation() {
      console.log('Rechecking geolocation')
      if (this.$globals.isCordova) {
        this.getGeoLocation(this.checkpointDetailsModal)
      } else {
        this.$bvToast.toast('Location services are only available on mobile devices', {
          title: 'Location Check',
          variant: 'warning',
          solid: true,
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
#fileInput {
  display: none;
}
.radio-spacing .custom-control {
    margin-top: 10px; /* Adjust this value to increase or decrease spacing */
}
.sortable-chosen {
  background-color: #f8f9fa;
  cursor: grabbing;
}

.sortable-drag {
  opacity: 0.8;
}
.draggable-row {
  cursor: grab;
}
</style>
